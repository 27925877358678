var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user.information
    ? _c(
        "section",
        [
          _vm.loaded
            ? _c(
                "ejs-schedule",
                {
                  key: _vm.redrawKey,
                  ref: "ScheduleObj",
                  attrs: {
                    readOnly: "true",
                    height: _vm.heightPixels,
                    firstDayOfWeek: _vm.firstDay,
                    locale: _vm.user.information.locale,
                    currentView: _vm.currentView,
                    actionComplete: _vm.onActionComplete,
                    showHeaderBar: "false",
                    allowInline: "false",
                    allowAdding: "false",
                    allowEditing: "false",
                    enableMaxHeight: "false",
                    allowMultiRowSelection: "false",
                    showQuickInfo: "false",
                    eventSettings: _vm.eventSettings,
                    eventRendered: _vm.oneventRendered,
                  },
                  on: {
                    cellClick: _vm.onCellClick,
                    cellDoubleClick: _vm.onCellDoubleClick,
                    popupOpen: _vm.popupOpen,
                    navigating: _vm.navigating,
                    eventClick: _vm.onEventClick,
                  },
                },
                [
                  _c(
                    "e-views",
                    [
                      _c("e-view", {
                        attrs: {
                          option: "Day",
                          eventTemplate: _vm.agendaEventTemplate,
                        },
                      }),
                      _c("e-view", {
                        attrs: {
                          option: "Week",
                          eventTemplate: _vm.agendaEventTemplate,
                        },
                      }),
                      _c("e-view", {
                        attrs: {
                          option: "WorkWeek",
                          eventTemplate: _vm.agendaEventTemplate,
                        },
                      }),
                      _c("e-view", {
                        attrs: {
                          option: "Agenda",
                          eventTemplate: _vm.agendaEventTemplate,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }