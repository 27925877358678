<template>
  <div class="clickable" v-if="data" v-b-tooltip.hover :title="GetTitle">
    <img class="icon mr-2" :src="messageIcon(data.Location)" /> {{data.Subject}}
    <br />
    <small>{{startTime}} - {{ endTime }}<br />
    {{ $t('MESSAGES.TYPE.' + data.Location) }}</small>
  </div>
</template>
<script>
export default {
  data() {
    return {
      days: 0
    }
  },
  methods: {
    messageIcon(message_type)
    {
      return "/img/message_type/" + message_type + ".png";
    },
    getFormattedTime(data)
    {
      return this.FormatDateTime(data);
    },
    GetTitle() {
      if(this.IsCreator){
        return "";
      }else{
        return this.data.FromName;
      }
    }
  },
  computed: {
    IsCreator()
    {
      if(this.data.FromUserUuid == undefined) 
      {
        return true;  
      }
      return this.user.information.user_uuid == this.data.FromUserUuid;
    },
    startTime(){
      if(this.SameDay)
      {
        return this.data.StartTime.getHours() + ":" + this.data.StartTime.getMinutes();
      }else{
        return this.getFormattedTime(this.data.StartTime.toISOString());
      }
    },
    endTime(){
      if(this.SameDay)
      {
        return this.data.EndTime.getHours() + ":" + this.data.EndTime.getMinutes();
      }else{
        return this.getFormattedTime(this.data.EndTime.toISOString());
      }
    },
    SameDay(){
      return (this.data.StartTime.getMonth() == this.data.EndTime.getMonth() && this.data.StartTime.getYear() == this.data.EndTime.getYear() && this.data.StartTime.getDay() == this.data.EndTime.getDay());
    }
  },
  mounted: function() {
  },
};
</script>
<style>
  </style>
